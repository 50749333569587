// Location 菜单
// - name: 菜单名，会用intl.get国际化
// - value: 代表URL。如果有多个，可选择
// - roles: 可看见的角色名
// - showNum: 展示徽标
// - childrens: 如果有，增加下拉分店列表,注意目前只支持分店列表dropdown
// - excludeSpaces: 用于对某些空间隐藏该菜单， spaceid 数组。例如[345, 455]。默认所有空间显示。可选
// - spaceTypes：对该spaceType显示，可选，默认只对正常空间显示 space_type === 'space'。目前支持：'thinkplus' - 联想空间（等于正常空间） space - 正常空间，temporary - 暂时性空间, 同时展示 = ['space', 'temporary', 'thinkplus']
// - locationTypes: 对该locationType显示
export const DEFAULT_LOCATION_MENU = [
  {
    name: 'desks.houses.document_title',
    value: 'areas',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'crm_admin',
      'crm',
    ],
    features: ['asset_management', 'custom'],
    spaceTypes: ['space', 'temporary', 'thinkplus'],
    submenu: [
      {
        name: 'area.area_manage.profile',
        value: 'profile',
      },
      {
        name: 'area.area_manage.table',
        value: 'table',
      },
      {
        name: 'location_menu.floorinfo',
        value: 'floorinfo',
        spaceTypes: ['space', 'thinkplus'],
        // features: ['asset_management', 'custom']
      },
    ],
  },
  {
    name: 'location_menu.organizations',
    value: 'organizations',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'crm_admin',
      'crm',
    ],
    features: ['asset_management', 'custom'],
    spaceTypes: ['space', 'temporary', 'thinkplus'],
    submenu: [
      {
        name: 'organization.organization.org_enter.title',
        value: 'current',
      },
      {
        name: 'organization.organization.button.off_hire',
        value: 'exited',
      },
    ],
  },
  {
    name: 'location_menu.members',
    value: 'memberships',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'membership',
      'content',
      'point_recharge',
      'observer',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'application.applications.thead.entered',
        value: 'members',
      },
      {
        name: 'application.applications.thead.common_user',
        value: 'non_resident_members',
      },
    ],
  },
  {
    name: 'shuck.admin_box.default_top_menu.members_ships',
    value: 'membership',
    roles: ['admin', 'membership', 'content', 'point_recharge'],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.app_manage',
        value: 'faq',
        roles: ['admin', 'faq'],
        // features: ['custom']
      },
    ],
  },

  {
    name: 'memberships.memberships_manage.menu.message_management',
    value: 'message_management',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'membership',
      'content',
      'point_recharge',
      'observer',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'announcements.announcements.announcement',
        value: 'announcement',
        roles: [
          'admin',
          'location_operator',
          'location_manager',
          'membership',
          'location_group_operator',
          'location_group_manager',
          'observer',
          'content',
        ],
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.news',
        value: 'news',
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.message',
        value: 'message',
        // features: ['custom']
      },
    ],
  },
  {
    name: 'location_menu.invoices',
    value: 'invoices',
    roles: [
      'admin',
      'location_manager',
      'observer',
      'finance',
      'location_operator',
      'contract',
      'location_group_operator',
      'location_group_manager',
      'finance',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'contract_terminate',
      'crm_admin',
      'crm',
    ],
    features: ['asset_management', 'custom'],
    excludeSpaces:[135638],
    submenu: [
      {
        name: 'shuck.announcement_box.invoice_one',
        value: 'subscriptions',
        // features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.announcement_box.invoice_list',
        value: 'invoices',
        // features: ['asset_management', 'custom']
      },
      {
        name: 'shuck.announcement_box.invoice_orders',
        value: 'orders',
        features: ['custom'],
      },
      {
        name: 'shuck.announcement_box.income_expenses',
        value: 'income_expenses', // 流水
        // features: ['asset_management', 'custom']
      },
      // {
      //   name: 'shuck.announcement_box.invoice_payments',
      //   value: 'invoice_payments'
      //   //features: ['custom']
      // },
      // {
      //   name: 'shuck.announcement_box.invoice_application',
      //   value: 'invoices_applications',
      //   isShow: true
      // },
      {
        name: 'location_menu.invoice_management',
        value: 'invoice_management',
        // isShow: false
        // features: ['custom']
      },
      {
        name: 'location_menu.invoicing_receipts',
        value: 'invoicing_receipts',
        // isShow: false
        // features: ['custom']
      },
      // {
      //   name: 'location_menu.cost_type',
      //   value: 'cost_type',
      //   isShow: false
      //   //features: ['custom']
      // },
      // {
      //   name: 'shuck.announcement_box.invoice_pool',
      //   value: 'invoicing_pool'
      // },
      {
        name: 'shuck.announcement_box.invoice_deposit',
        value: 'deposits',
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.cycle_fees',
        value: 'cycle_fees',
        // features: ['custom']
      },
      // {
      //   name: 'location_menu.invoice',
      //   value: 'invoice',
      //   spaceTypes: ['space', 'thinkplus']
      // },
      // {
      //   name: 'location_menu.payment',
      //   value: 'payment',
      //   spaceTypes: ['space', 'thinkplus'],
      //   roles: ['admin', 'location_manager', 'location_operator', '']
      // },
      // {
      //   name: 'shuck.announcement_box.cost_books',
      //   value: 'cost_books'
      //   // features: ['asset_management', 'custom']
      // },
      {
        name: 'shuck.announcement_box.fashionable',
        value: 'fashionable',
        features: ['custom'],
      },
      {
        name: 'shuck.announcement_box.monthly_invoices',
        value: 'monthly_invoices',
        features: ['custom'],
      },
      {
        name: 'shuck.announcement_box.confirmed_invoices',
        value: 'confirmed_invoices',
        features: ['custom'],
      },
    ],
  },

  {
    name: 'location_menu.tasks',
    value: 'tasks',
    showNum: true,
    roles: [
      'admin',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'task_admin',
      'location_manager',
      'observer',
      'repair',
      'repair_manager',
      'clean',
      'clean_manager',
      'feedback',
      'feedback_manager',
      // 'custom_service',
      'task_suggest_manager',
      'task_complaint',
      'task_complaint_manager',
      'task_ac_overtime_service_manager',
    ],
    features: ['custom'],
    submenu: [
      // 空调加时隐藏
      {
        name: 'shuck.announcement_box.ac_overtime',
        value: 'ac_overtime',
        isShow: false,
        showNum: true,
        roles: [
          'task_feedback',
          'task_admin',
          'feedback',
          'feedback_manager',
          'admin',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'location_manager',
          'observer',
          'task_ac_overtime_service_manager',
        ],
        // features: ['custom']
      },
      // 新风加时
      {
        name: 'shuck.announcement_box.wind_overtime',
        value: 'wind_overtime',
        isShow: false,
        showNum: true,
        roles: [
          'task_feedback',
          'task_admin',
          'feedback',
          'feedback_manager',
          'admin',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'location_manager',
          'observer',
          'task_ac_overtime_service_manager',
        ],
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.tasks_repair',
        showNum: true,
        value: 'repair',
        roles: [
          'repair',
          'task_admin',
          'repair_manager',
          'admin',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'location_manager',
          'observer',
        ],
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.tasks_cleaner',
        value: 'clean',
        showNum: true,
        roles: [
          'clean',
          'task_admin',
          'clean_manager',
          'admin',
          'location_operator',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'observer',
        ],
        // features: ['custom']
      },
      // {
      //   name: 'shuck.announcement_box.tasks_custom_service',
      //   value: 'custom_service',
      //   showNum: true,
      //   roles: [
      //     'custom_service',
      //     'admin',
      //     'location_operator',
      //     'location_manager',
      //     'observer'
      //   ]
      // },
      // {
      //   name: 'shuck.announcement_box.tasks_feedback',
      //   value: 'feedback',
      //   showNum: true,
      //   roles: [
      //     'feedback',
      //     'task_admin',
      //     'feedback_manager',
      //     'admin',
      //     'location_operator',
      //     'location_manager',
      //     'location_group_operator',
      //     'location_group_manager',
      //     'observer',
      //     'task_suggest_manager',
      //     'task_complaint',
      //     'task_complaint_manager'
      //   ]
      // },
      // 投诉
      {
        name: 'shuck.announcement_box.complaint',
        value: 'complaint',
        showNum: true,
        roles: [
          'feedback',
          'task_admin',
          'feedback_manager',
          'admin',
          'location_operator',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'observer',
          'task_suggest_manager',
          'task_complaint',
          'task_complaint_manager',
        ],
      },
      // 建议
      {
        name: 'shuck.announcement_box.suggest',
        value: 'suggest',
        showNum: true,
        roles: [
          'feedback',
          'task_admin',
          'feedback_manager',
          'admin',
          'location_operator',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'observer',
          'task_suggest_manager',
          'task_complaint',
          'task_complaint_manager',
        ],
      },
      // 空调加时隐藏
      // {
      //   name: 'shuck.announcement_box.ac_overtime_rules',
      //   value: 'ac_overtime_rules',
      //   isShow: false,
      //   roles: [
      //     'task_feedback',
      //     'feedback',
      //     'feedback_manager',
      //     'admin',
      //     'location_operator',
      //     'location_manager',
      //     'observer',
      //     'task_ac_overtime_service_manager'
      //   ]
      // }
    ],
  },

  {
    name: 'location_menu.meeting',
    value: 'meeting',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'meeting_room_auditor',
      'cancel_reservation_auditor',
      'meeting_room_manager',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.meeting_box.menu.book',
        value: 'book',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.manage',
        value: 'manage',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.record',
        value: 'record',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.application',
        value: 'application',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.cancel',
        value: 'cancel',
      },
      // {
      //   name: 'shuck.meeting_box.menu.meeting_service_manage',
      //   value: 'meeting_service_manage',
      // },
      {
        name: 'shuck.meeting_box.menu.meeting_service_order',
        value: 'meeting_service_order',
      },

      {
        name: 'shuck.meeting_box.menu.devices',
        value: 'devices',
      },
      // {
      //   name: 'location_menu.meeting_settings',
      //   value: 'settings',
      // },
    ],
  },
  {
    name: 'location_menu.locks',
    value: 'locks',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'observer',
      'location_group_operator',
      'location_group_manager',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.announcement_box.lock_record_name',
        value: '',
        // features: ['custom']
      },

      {
        name: 'shuck.announcement_box.lock_info_name',
        value: 'lock_info',
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.lock_groups',
        value: 'lock_groups',
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.lock_permission',
        value: 'lock_permission',
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.lock_intelligent_analysis',
        value: 'int_analysis',
        // features: ['custom']
      },
      {
        name: 'shuck.announcement_box.lock_synchronize_records',
        value: 'synchronize_records',
        // features: ['custom']
      },
      {
        name: 'location_menu.lock_permission',
        value: 'settings',
        // features: ['custom']
      },
    ],
  },
  {
    name: 'location_menu.visit',
    value: 'visit',
    roles: [
      'admin',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'location_manager',
      'observer',
      'sales',
      'visitor_approve',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'visit.visit_box.index',
        value: 'visits',
        // features: ['custom']
      },
      {
        name: 'visit.visit_box.blacklist',
        value: 'blacklist',
        // features: ['custom']
      },
      // {
      //   name: 'visit.visit_box.devices',
      //   value: 'devices'
      // }
    ],
  },
  // {name: 'location_menu.app', value: 'application', roles: ['admin', 'location_manager', 'location_operator', 'observer']},
  // {name: 'location_menu.coupons', value: 'coupons', roles: ['admin', 'location_manager', 'location_operator', 'observer']},
  // {name: 'location_menu.orders', value: 'orders', roles: ['admin', 'location_manager', 'observer', 'finance', 'location_operator']},
  // {name: 'location_menu.activities', value: 'activities', roles: ['admin', 'location_manager', 'location_operator']},
  {
    name: 'location_menu.marketing',
    value: 'activities',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'marketing',
      'content',
      'activity',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.announcement_box.point_plans',
        value: 'point_plans',
        features: ['custom'],
      },
      {
        name: 'shuck.location_box.default_location_menu.marketing_activities',
        value: 'marketing_activities',
        // features: ['custom']
      },
      {
        name: 'memberships.memberships_coupons.document_title',
        value: 'coupons',
        // features: ['custom']
      },
      {
        name: 'coupons.coupons_box.release_record',
        value: 'release_record',
        // features: ['custom']
      },
      {
        name: 'coupons.coupons_box.usage_record',
        value: 'usage_record',
        // features: ['custom']
      },
    ],
  },
  {
    name: 'location_menu.elevatorControl',
    value: 'elevator',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'marketing',
      'content',
      'activity',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.elevator.control.record',
        value: 'record',
        features: ['custom'],
      },
      {
        name: 'shuck.elevator.control.administration',
        value: 'administration',
        features: ['custom'],
      },
      {
        name: 'shuck.elevator.control.authority',
        value: 'authority',
        features: ['custom'],
      },
    ],
  },
  {
    name: 'location_menu.activity',
    value: 'marketing',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'marketing',
      'content',
      'activity',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'location_menu.activity.list',
        value: 'activity',
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.service',
        value: 'service',
        showSpace: [364, 50495],
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.activities_setting',
        value: 'activities_setting',
        // features: ['custom']
      },
      // {
      //   name: 'shuck.announcement_box.tasks_custom_service',
      //   value: 'virtual'
      // },
      // {
      //   name: 'shuck.location_box.default_location_menu.surveys',
      //   value: 'surveys'
      // }
    ],
  },
  // {name: 'location_menu.news', value: 'news', roles: ['location_manager', 'location_operator']},

  // {
  //   name: 'location_menu.covid19',
  //   value: 'epidemic_report',
  //   roles: ['admin', 'location_manager', 'location_operator', 'observer'],
  //   spaceTypes: ['space', 'temporary', 'thinkplus'],
  //   submenu: [
  //     {
  //       name: 'epidemic_report.locations_menu.epidemic_black_list',
  //       value: ''
  //     },
  //     {
  //       name: 'epidemic_report.locations_menu.epidemic_white_list',
  //       value: 'epidemic_white_list'
  //     },
  //     {
  //       name: 'epidemic_report.locations_menu.white_list_upload',
  //       value: 'white_list_upload'
  //     }
  //   ]
  // },
  {
    name: 'location_menu.smart_devices',
    value: 'smart_device',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.meeting_box.menu.devices',
        value: 'devices',
        // features: ['custom']
      },

      {
        name: 'location_menu.lockers',
        value: 'lockers',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'observer',
        ],
        // features: ['custom']
      },
      {
        name: 'location_menu.printer',
        value: 'printers',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'observer',
        ],
        // features: ['custom']
      },

      {
        name: 'location_menu.lighting',
        value: 'lighting',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'observer',
        ],
        // features: ['custom']
      },
      {
        name: 'location_menu.sensor',
        value: 'sensor',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'observer',
        ],
        // features: ['custom']
      },
    ],
  },
  {
    name: 'location_menu.property_meter_reading',
    value: 'property_meter_reading',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'package_in_record_user',
      'package_out_record_user',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'location_menu.stats_daily_yunding_device_data',
        value: '',
      },
    ],
  },
  {
    name: 'location_menu.parking',
    value: 'parking',
    isShow: false,
    roles: [
      'admin',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'location_manager',
      'observer',
      'parking_manager',
      'parking_contract',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'parking.card_handle',
        value: 'card_handle',
        // features: ['custom']
      },
      {
        name: 'parking.payment_records',
        value: 'payment_records',
        // features: ['custom']
      },
      {
        name: 'parking.approval',
        value: 'parking_approval',
        // features: ['custom']
      },
      {
        name: 'parking.violation',
        value: 'parking_violation',
        // features: ['custom']
      },
      {
        name: 'parking.black',
        value: 'parking_black',
        // features: ['custom']
      },
      {
        name: 'parking.set',
        value: 'settings',
        // features: ['custom']
      },
    ],
  },
  // 云商服务
  {
    name: 'shuck.admin_box.default_top_menu.cloud_business_services',
    value: 'cloud_business_services',
    roles: ['admin', 'enterprise', 'clound_business_service_manager'],
    features: ['custom'],
    excludeSpaces:[135638],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.cloud_business_services',
        value: 'list',
        // features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.activity',
        value: 'activity',
        // features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.rights_and_interests',
        value: 'rights_and_interests',
        // features: ['custom']
      },
      {
        name: 'shuck.admin_box.default_top_menu.cooperative_resources',
        value: 'cooperative_resources',
        // features: ['custom']
      },
    ],
  },
  // 数据分析
  {
    name: 'location.data_analysis',
    value: 'data_analysis',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'statistic',
    ],
    submenu: [
      {
        name: 'organization.organization.menu.asset_appraisal',
        value: 'asset_appraisal',
      },
      {
        name: 'organization.organization.menu.resource_analysis',
        value: 'resource_analysis',
      },
      {
        name: 'organization.organization.menu.lease_analysis',
        value: 'lease_analysis',
      },
      {
        name: 'organization.organization.menu.tenants_analysis',
        value: 'tenants_analysis',
      },
      {
        name: 'organization.organization.menu.task',
        value: 'task',
      },
      // {
      //   name: 'organization.organization.menu.client_analysis',
      //   value: 'client_analysis'
      // }
    ],
  },
  {
    name: 'shuck.admin_box.default_top_menu.shopping_management',
    value: 'shopping',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'shop_manager',
      'shop',
      'sales',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.shopping_list',
        value: 'list',
      },
      {
        name: 'shopping.orders',
        value: 'orders',
      },
      {
        name: 'shopping.activity',
        value: 'activity',
      },
    ],
  },
  {
    name: 'shuck.admin_box.default_top_menu.operation',
    value: 'operation',
    roles: ['admin'],
    features: ['custom', 'asset_management'],
    excludeSpaces:[135638],
    submenu: [
      {
        name: 'shuck.announcement_box.cost_books',
        value: 'cost_books',
      },
      {
        name: 'shuck.announcement_box.cost_contract',
        value: 'cost_contracts',
      },
      {
        name: 'shuck.announcement_box.invoice_list',
        value: 'invoices',
      },
      {
        name: 'shuck.announcement_box.income_books',
        value: 'income_books',
      },
    ],
  },
  {
    name: 'location_menu.setting',
    value: 'settings',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'crm_admin',
      'crm',
      'observer',
      'finance',
      'contract',
      'finance',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'contract_terminate',
    ],
    spaceTypes: ['space', 'temporary', 'thinkplus'],
    // features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'location_menu.basic_setting',
        value: 'basic',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          '',
        ],
        features: ['asset_management', 'custom'],
      },
      {
        name: 'location_menu.role',
        value: 'role',
        roles: ['admin', 'location_manager'],
        spaceTypes: ['space', 'temporary', 'thinkplus'],
        features: ['asset_management', 'custom'],
      },
      {
        name: 'shuck.admin_box.default_top_menu.employee',
        value: 'employees',
        roles: ['admin', 'location_manager', 'hr'],
        // features: ['asset_management', 'custom']
      },
      {
        name: 'location_menu.finance',
        value: 'finance',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          'finance',
        ],
        spaceTypes: ['space', 'thinkplus'],
        features: ['asset_management', 'custom'],
      },
      {
        name: 'shuck.announcement_box.task_type',
        value: 'overtime',
        roles: ['admin', 'location_manager'],
        features: [ 'custom'],
      },
      {
        name: 'shuck.spaces_setting_box.menu.options',
        value: 'options',
        roles: [
          'admin',
          'location_manager',
          'location_operator',
          'location_group_operator',
          'location_group_manager',
          '',
        ],
        features: ['custom'],
      },
      {
        name: 'shuck.spaces_setting_box.menu.listener',
        value: 'notifications',
        roles: [
          'admin',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'location_operator',
        ],
        spaceTypes: ['space', 'temporary', 'thinkplus'],
        features: ['asset_management', 'custom'],
      },
      {
        name: 'visit.visit_box.settings',
        value: 'visit_settings',
        features: ['custom'],
      },
      {
        name: 'location_menu.meeting_settings',
        value: 'meeting_settings',
        features: ['custom'],
      },
      {
        name: 'location_menu.tenement_settings',
        value: 'tenement_settings',
      },
      // taoh - 数据导入不用在设置里面。
      // {
      //   name: 'location_menu.import',
      //   value: 'import_data',
      //   roles: ['admin', 'location_manager', 'location_operator', ''],
      //   features: ['asset_management', 'custom']
      // },
      // {
      //   name: 'location_menu.application',
      //   value: 'application',
      //   spaceTypes: ['space', 'thinkplus'],
      //   roles: ['admin', 'location_manager', 'location_operator', ''],
      //   features: ['custom']
      // },
      {
        name: 'location_menu.clients',
        value: 'clients',
        spaceTypes: ['space', 'thinkplus'],
        roles: [
          'admin',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'location_operator',
        ],
        features: ['custom'],
      },
      {
        name: 'location_menu.area',
        value: 'area',
        spaceTypes: ['space', 'thinkplus'],
        roles: [
          'admin',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'location_operator',
          '',
        ],
        features: ['custom'],
      },
      {
        name: 'shuck.spaces_setting_box.menu.screen_config',
        value: 'screen_config',
        roles: [
          'admin',
          'location_manager',
          'location_group_operator',
          'location_group_manager',
          'location_operator',
          '',
        ],
      },

      // {
      //   name: 'logistics.logistics_box.delivery_point',
      //   value: 'delivery_point'
      // },
      // {
      //   name: 'location_menu.station',
      //   value: 'station'
      // },
    ],
  },
  // {name: 'shuck.admin_box.default_top_menu.statistic', value: 'statistic', roles: ['admin']}
]
export const KINEER_LOCATION_MENU = [
  {
    name: 'desks.houses.document_title',
    value: 'areas',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'crm_admin',
      'crm',
    ],
    features: ['asset_management', 'custom'],
    spaceTypes: ['space', 'temporary', 'thinkplus'],
    submenu: [
      {
        name: 'area.area_manage.profile',
        value: 'profile',
      },
      {
        name: 'area.area_manage.table',
        value: 'table',
      },
      {
        name: 'location_menu.floorinfo',
        value: 'floorinfo',
        spaceTypes: ['space', 'thinkplus'],
        // features: ['asset_management', 'custom']
      },
    ],
  }, {
    name: 'location_menu.meeting',
    value: 'meeting',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'meeting_room_auditor',
      'cancel_reservation_auditor',
      'meeting_room_manager',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.meeting_box.menu.book',
        value: 'book',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.manage',
        value: 'manage',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.record',
        value: 'record',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.application',
        value: 'application',
        // features: ['custom']
      },
      {
        name: 'shuck.meeting_box.menu.cancel',
        value: 'cancel',
      },
      // {
      //   name: 'shuck.meeting_box.menu.meeting_service_manage',
      //   value: 'meeting_service_manage',
      // },
      {
        name: 'shuck.meeting_box.menu.meeting_service_order',
        value: 'meeting_service_order',
      },

      {
        name: 'shuck.meeting_box.menu.devices',
        value: 'devices',
      },
      {
        name: 'location_menu.meeting_settings',
        value: 'settings',
      },
    ],
  },
  {
    name: 'memberships.memberships_manage.menu.message_management',
    value: 'message_management',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'membership',
      'content',
      'point_recharge',
      'observer',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'announcements.announcements.announcement',
        value: 'announcement',
        roles: [
          'admin',
          'location_operator',
          'location_manager',
          'membership',
          'location_group_operator',
          'location_group_manager',
          'observer',
          'content',
        ],
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.news',
        value: 'news',
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.message',
        value: 'message',
        // features: ['custom']
      },
    ],
  },
  {
    name: 'shuck.admin_box.default_top_menu.members_ships',
    value: 'memberships',
    roles: [
      'admin',
      'membership',
      'content',
      'point_recharge',
      'location_group_operator',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'memberships.memberships_manage.menu.members',
        value: 'members',
        roles: [
          'admin',
          'membership',
          'point_recharge',
          'location_group_operator',
        ],
        // features: ['custom']
      },

      // {
      //   name: 'memberships.memberships_manage.menu.posts',
      //   value: 'posts',
      //   roles: [
      //     'admin',
      //     'membership',
      //     'content',
      //     'point_recharge',
      //     'location_group_operator'
      //   ]
      //   //features: ['custom']
      // },
      // {
      //   name: 'memberships.memberships_manage.menu.activities',
      //   value: 'activity_box',
      //   roles: [
      //     'admin',
      //     'membership',
      //     'content',
      //     'activity',
      //     'location_group_operator'
      //   ]
      //   //features: ['custom']
      // },
      // {
      //   name: 'shuck.location_box.default_location_menu.surveys',
      //   value: 'surveys'
      //   //features: ['custom']
      // },
      // {
      //   name: 'shuck.admin_box.default_top_menu.app_manage',
      //   value: 'faq',
      //   roles: ['admin', 'faq', 'location_group_operator']
      //   //features: ['custom']
      // }
    ],
  }, {
    name: 'location_menu.marketing',
    value: 'activities',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'marketing',
      'content',
      'activity',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.announcement_box.point_plans',
        value: 'point_plans',
        features: ['custom'],
      },
      {
        name: 'shuck.location_box.default_location_menu.marketing_activities',
        value: 'marketing_activities',
        // features: ['custom']
      },
      {
        name: 'memberships.memberships_coupons.document_title',
        value: 'coupons',
        // features: ['custom']
      },
      {
        name: 'coupons.coupons_box.release_record',
        value: 'release_record',
        // features: ['custom']
      },
      {
        name: 'coupons.coupons_box.usage_record',
        value: 'usage_record',
        // features: ['custom']
      },
    ],
  }, {
    name: 'shuck.admin_box.default_top_menu.shopping_management',
    value: 'shopping',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'shop_manager',
      'shop',
      'sales',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'shuck.admin_box.default_top_menu.shopping_list',
        value: 'list',
      },
      {
        name: 'shopping.orders',
        value: 'orders',
      },
      {
        name: 'shopping.activity',
        value: 'activity',
      },
    ],
  }, {
    name: 'location_menu.activity',
    value: 'marketing',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'observer',
      'marketing',
      'content',
      'activity',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'location_menu.activity.list',
        value: 'activity',
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.service',
        value: 'service',
        showSpace: [364, 50495],
        // features: ['custom']
      },
      {
        name: 'shuck.location_box.default_location_menu.activities_setting',
        value: 'activities_setting',
        // features: ['custom']
      },
      // {
      //   name: 'shuck.announcement_box.tasks_custom_service',
      //   value: 'virtual'
      // },
      // {
      //   name: 'shuck.location_box.default_location_menu.surveys',
      //   value: 'surveys'
      // }
    ],
  }, {
    name: 'location_menu.visit',
    value: 'visit',
    roles: [
      'admin',
      'location_operator',
      'location_group_operator',
      'location_group_manager',
      'location_manager',
      'observer',
      'sales',
      'visitor_approve',
    ],
    features: ['custom'],
    submenu: [
      {
        name: 'visit.visit_box.index',
        value: 'visits',
        // features: ['custom']
      },
      {
        name: 'visit.visit_box.blacklist',
        value: 'blacklist',
        // features: ['custom']
      },
      // {
      //   name: 'visit.visit_box.devices',
      //   value: 'devices'
      // }
    ],
  }, {
    name: 'shuck.admin_box.default_top_menu.setting',
    value: 'settings',
    roles: [
      'admin',
      'shop_manager',
      'shop',
      'hr',
      'app',

      'finance',
      'contract',
      'contract_terminate',
      'abnormal_subscription',
      'invoice_check',
      'invoice_confirm',
      'invoice_check_notify',
      'invoice_refund',
      'crm_admin',
      'crm',
      'location_group_operator',
    ],
    // features: ['asset_management', 'custom'],
    submenu: [
      {
        name: 'location_menu.basic_setting',
        value: 'basic',
        roles: ['admin', 'hr', 'location_group_operator'],
        features: ['asset_management', 'custom'],
      },
      {
        name: 'location_menu.role',
        value: 'location_group_role',
        roles: ['admin', 'hr', 'location_group_operator'],
        spaceTypes: ['space', 'temporary', 'thinkplus'],
        features: ['asset_management', 'custom'],
      },
    ],
  },
]
