import React from 'react';
import { Route } from 'react-router-dom';
import lazyLoad from 'utils/lazyLoad'

// 会议室
const MeetingBox = lazyLoad(() => import('containers/shuck/MeetingBox'))
const MeetingBookingApplication = lazyLoad(() => import('containers/meeting/MeetingBookingApplication'))
const MeetingBookingCancel = lazyLoad(() => import('containers/meeting/MeetingBookingCancel'))
const MeetingIndex = lazyLoad(() => import('containers/meeting/MeetingIndex'))
const MeetingInfo = lazyLoad(() => import('containers/meeting/MeetingInfo'))
const MeetingInfoBox = lazyLoad(() => import('containers/meeting/MeetingInfoBox'))
const MeetingInfoSettingBox = lazyLoad(() => import('containers/meeting/MeetingInfoSettingBox'))
const MeetingManage = lazyLoad(() => import('containers/meeting/MeetingManage'))
const MeetingRecord = lazyLoad(() => import('containers/meeting/MeetingRecord'))
const MeetingServiceManage = lazyLoad(() => import('containers/meeting/MeetingServiceManage'))
const MeetingServiceOrder = lazyLoad(() => import('containers/meeting/MeetingServiceOrder'))
const MeetingDeviceDetail = lazyLoad(() => import('../containers/meeting/MeetingDeviceDetail'))
const MeetingDeviceList = lazyLoad(() => import('../containers/meeting/MeetingDeviceList'))
const MeetingSettings = lazyLoad(() => import('../containers/meeting/MeetingSettings'))

// 活动
const ActivitiesInfo = lazyLoad(() => import('containers/activities/ActivitiesInfo'))
const ActivitiesList = lazyLoad(() => import('containers/activities/ActivitiesList'))
const ActivitiesSettingList = lazyLoad(() => import('containers/activities/ActivitiesSettingList'))
const ActivityBox = lazyLoad(() => import('containers/activities/ActivityBox'))
const ActivityForm = lazyLoad(() => import('containers/activities/ActivityForm'))
const ServiceActivity = lazyLoad(() => import('containers/activities/ServiceActivity'))

// 锁
const LockerCategories = lazyLoad(() => import('containers/lockers/LockerCategories'))
const LockerRecord = lazyLoad(() => import('containers/lockers/LockerRecord'))
const Lockers = lazyLoad(() => import('containers/lockers/Lockers'))
const LockersBox = lazyLoad(() => import('containers/lockers/LockersBox'))
const LockEvents = lazyLoad(() => import('containers/locks/LockEvents'))
const LockGroupDetail = lazyLoad(() => import('containers/locks/LockGroupDetail'))
const LockGroups = lazyLoad(() => import('containers/locks/LockGroups'))
const SynchronizeRecords = lazyLoad(() => import('containers/locks/SynchronizeRecords'))

const LockInactiveAreas = lazyLoad(() => import('containers/locks/LockInactiveAreas'))
const LockInactiveUsers = lazyLoad(() => import('containers/locks/LockInactiveUsers'))
const LockInfo = lazyLoad(() => import('containers/locks/LockInfo'))
const LockPermissions = lazyLoad(() => import('containers/locks/LockPermissions'))
const Locks = lazyLoad(() => import('containers/locks/Locks'))

const Announcements = lazyLoad(() => import('containers/announcements/Announcements'))
const BroadcastMessages = lazyLoad(() => import('containers/announcements/BroadcastMessages'))
const CouponsDetails = lazyLoad(() => import('containers/coupons/CouponsDetails'))
const CouponsManageBox = lazyLoad(() => import('containers/coupons/CouponsManageBox'))
const CouponsRecord = lazyLoad(() => import('containers/coupons/CouponsRecord'))
const CouponsReleaseRecord = lazyLoad(() => import('containers/coupons/CouponsReleaseRecord'))
const CouponsUsageRecord = lazyLoad(() => import('containers/coupons/CouponsUsageRecord'))
const MarketingActivitiesRecord = lazyLoad(() => import('containers/coupons/MarketingActivitiesRecord'))
const DevicesList = lazyLoad(() => import('containers/devices/DevicesList'))
const EpidemicBox = lazyLoad(() => import('containers/epidemic/EpidemicBox'))
const PointPlansLedgers = lazyLoad(() => import('containers/invoices/PointPlansLedgers'))

const ElevatorRecord = lazyLoad(() => import('containers/elevator/record'))
const ElevatorAdministration = lazyLoad(() => import('containers/elevator/administration'))
const ElevatorAuthority = lazyLoad(() => import('containers/elevator/authority'))


const NewsDetail = lazyLoad(() => import('containers/news/NewsDetail'))
const NewsList = lazyLoad(() => import('containers/news/NewsList'))
const CreateOrganization = lazyLoad(() => import('containers/organization/createOrganization/CreateOrganization'))
const ExitedOrganizations = lazyLoad(() => import('containers/organization/ExitedOrganizations'))
const Organizations = lazyLoad(() => import('containers/organization/Organizations'))
const PostDetail = lazyLoad(() => import('containers/posts/PostDetail'))
const ServiceCooperativeResources = lazyLoad(() => import('containers/service/ServiceCooperativeResources'))
const ServiceLists = lazyLoad(() => import('containers/service/ServiceLists'))
const IntAnalysisBox = lazyLoad(() => import('containers/shuck/IntAnalysisBox'))
const LockBox = lazyLoad(() => import('containers/shuck/LockBox'))
const LockManageBox = lazyLoad(() => import('containers/shuck/LockManageBox'))

const AssetAppraisal = lazyLoad(() => import('containers/spaces/AssetAppraisal'))
const ClientAnalysis = lazyLoad(() => import('containers/spaces/ClientAnalysis'))
const FloorManage = lazyLoad(() => import('containers/spaces/FloorManage'))
const LeaseAnalysis = lazyLoad(() => import('containers/spaces/LeaseAnalysis'))
const ResourceAnalysis = lazyLoad(() => import('containers/spaces/ResourceAnalysis'))
const LightingDeviceManage = lazyLoad(() => import('containers/spaces/setting/location/LightingDeviceManage'))
const SensorDeviceManage = lazyLoad(() => import('containers/spaces/setting/location/SensorDeviceManage'))
const Task = lazyLoad(() => import('containers/spaces/Task'))
const TenantsAnalysis = lazyLoad(() => import('containers/spaces/TenantsAnalysis'))
const SurveyInfoBox = lazyLoad(() => import('containers/surveys/SurveyInfoBox'))
const SurveyInfoData = lazyLoad(() => import('containers/surveys/SurveyInfoData'))
const SurveyInfoReport = lazyLoad(() => import('containers/surveys/SurveyInfoReport'))

const AreaManage = lazyLoad(() => import('../containers/area/AreaManage'))
const AreaProfile = lazyLoad(() => import('../containers/area/AreaProfile'))
const LockerSettings = lazyLoad(() => import('../containers/lockers/LockerSettings'))
const LockSettings = lazyLoad(() => import('../containers/locks/LockSettings'))

const PrinterManage = lazyLoad(() => import('../containers/printers/PrinterManage'))
const IndexRedirectBox = lazyLoad(() => import('../containers/shuck/IndexRedirectBox'))
const LocationBox = lazyLoad(() => import('../containers/shuck/LocationBox'))
const PermissionBox = lazyLoad(() => import('../containers/shuck/PermissionBox'))
const WorkBench = lazyLoad(() => import('../containers/workbench/WorkBench'))


// Routes
import invoiceRoutes from './invoiceRoutes'
import locationSettingRoutes from './locationSettingRoutes'
import memberRoutes from './memberRoutes'
import operationRoutes from './operationRoutes'
import organizationRoutes from './organizationRoutes'
import parkingRoutes from './parkingRoutes'
import shoppingRoutes from './shoppingRoutes'
import taskRoutes from './taskRoutes'
import visitorRoutes from './visitorRoutes'
import assetResourceRoutes from './assetResourceRoutes'

export default function() {
    return (
        <Route path="locations/:id" element={LocationBox}>
            <Route index element={AreaProfile} />
            <Route path="areas" element={IndexRedirectBox}>
                <Route path="profile" element={AreaProfile} />
                <Route path="table" element={AreaManage} />
                <Route path="floor_list" element={LeaseAnalysis} />
                <Route path="floorinfo" element={FloorManage} />
            </Route>
            <Route path="work_bench" element={WorkBench} />
            <Route path="organizations" element={IndexRedirectBox}>
                <Route path="current" element={Organizations} />
                <Route path="exited" element={ExitedOrganizations} />
            </Route>
            <Route path="data_analysis" element={IndexRedirectBox}>
                <Route path="asset_appraisal" element={AssetAppraisal} />
                <Route path="resource_analysis" element={ResourceAnalysis} />
                <Route path="lease_analysis" element={LeaseAnalysis} />
                <Route path="tenants_analysis" element={TenantsAnalysis} />
                <Route path="task" element={Task} />
                <Route path="client_analysis" element={ClientAnalysis} />
            </Route>

            <Route
                path="organizations/new_organization"
                element={CreateOrganization}
            />

            <Route path="message_management" element={IndexRedirectBox}>
                <Route path="announcement" element={Announcements} />
                <Route path="news" element={PermissionBox}>
                    <Route index element={NewsList} />
                    <Route path=":news_id" element={NewsDetail} />
                </Route>
                <Route path="message" element={BroadcastMessages} />
            </Route>

            <Route path="activities" element={IndexRedirectBox}>
                <Route path="coupons" element={CouponsRecord} />
                <Route
                    path="marketing_activities"
                    element={MarketingActivitiesRecord}
                />
                <Route path="point_plans" element={PointPlansLedgers} />
                <Route path="release_record" element={CouponsReleaseRecord} />
                <Route path="usage_record" element={CouponsUsageRecord} />
            </Route>
            <Route path="elevator" element={IndexRedirectBox}>
                <Route path="record" element={ElevatorRecord} />
                <Route path="administration" element={ElevatorAdministration} />
                <Route path="authority" element={ElevatorAuthority} />
            </Route>

            <Route path="locks" element={LockBox}>
                <Route index element={LockEvents} />
                <Route path="lock_info" element={LockManageBox}>
                    <Route index element={Locks} />
                    <Route path="locks" element={Locks} />
                    <Route path="locks/:lock_id" element={LockInfo} />
                </Route>
                <Route path="lock_groups" element={LockGroups} />
                <Route path="lock_groups/:group_id" element={LockGroupDetail} />
                <Route path="int_analysis" element={IntAnalysisBox}>
                    <Route index element={LockInactiveUsers} />
                    <Route path="lock_unopened_report" element={LockInactiveUsers} />
                    <Route path="areas_unopened_report" element={LockInactiveAreas} />
                </Route>
                <Route path="lock_permission" element={LockPermissions} />
                <Route path="synchronize_records" element={SynchronizeRecords} />
                <Route path="settings" element={LockSettings} />
            </Route>

            <Route path="meeting" element={MeetingBox}>
                <Route path="book" element={MeetingIndex} />
                <Route path="record" element={MeetingRecord} />
                <Route path="application" element={MeetingBookingApplication} />
                <Route path="cancel" element={MeetingBookingCancel} />
                <Route path="meeting_service_order" element={MeetingServiceOrder} />
                <Route path="meeting_service_manage" element={MeetingServiceManage} />
                <Route path="manage" element={MeetingManage} />
                <Route path="settings" element={MeetingSettings} />
                <Route path="devices" element={MeetingDeviceList} />
                <Route path="devices/:devices_id" element={MeetingDeviceDetail} />
                <Route path=":meeting_id" element={MeetingInfoBox}>
                    <Route index element={MeetingInfo} />
                    <Route path="info" element={MeetingInfo} />
                    <Route path="settings" element={MeetingInfoSettingBox} />
                </Route>
            </Route>
            <Route path="marketing" element={IndexRedirectBox}>
                <Route path="coupons" element={CouponsManageBox}>
                    <Route path="coupons" element={CouponsRecord} />
                    <Route
                        path="marketing_activities"
                        element={MarketingActivitiesRecord}
                    />
                    <Route path="release_record" element={CouponsReleaseRecord} />
                    <Route path="usage_record" element={CouponsUsageRecord} />
                </Route>
                <Route path="coupons/:coupon_id" element={CouponsDetails} />
                <Route
                    path="coupons/release_record/:release_record_id"
                    element={CouponsDetails}
                />

                <Route path="activity" element={ActivityBox}>
                    <Route index element={ActivitiesList} />
                    <Route path="edit" element={ActivityForm} />
                    <Route path=":activity_id/info" element={ActivitiesInfo} />
                </Route>
                <Route path="service" element={ActivityBox}>
                    <Route index element={ActivitiesList} />
                    <Route path="edit" element={ActivityForm} />
                    <Route path=":activity_id/info" element={ActivitiesInfo} />
                </Route>

                <Route path="announcements" element={Announcements} />

                <Route path="news" element={PermissionBox}>
                    <Route index element={NewsList} />
                    <Route path=":news_id" element={NewsDetail} />
                </Route>
                <Route path="activities_setting" element={ActivitiesSettingList} />
                {/* <Route path='surveys/:id' element={<SurveyInfo />} /> */}

                <Route path="surveys/:survey_id" element={SurveyInfoBox}>
                    <Route index element={SurveyInfoReport} />
                    <Route path="report" element={SurveyInfoReport} />
                    <Route path="data" element={SurveyInfoData} />
                </Route>
            </Route>
            <Route path="news" element={PermissionBox}>
                <Route index element={NewsList} />
                <Route path=":news_id" element={NewsDetail} />
            </Route>

            {/* 疫情上报 */}
            {/* <Route path="epidemic_report" element={<EpidemicBox />}>
      <Route element={<EpidemicBox />} />
      <Route path="epidemic_white_list" element={<EpidemicWhiteList />} />
      <Route path="white_list_upload" element={<WhiteListUpload />} />
    </Route> */}
            {/* 云商服务 */}
            <Route path="cloud_business_services" element={IndexRedirectBox}>
                <Route path="list" element={ServiceLists} />
                <Route path="activity" element={ServiceActivity} />
                <Route path="rights_and_interests" element={ServiceLists} />
                <Route
                    path="cooperative_resources"
                    element={ServiceCooperativeResources}
                />
                <Route path="cooperative_resources/:post_id" element={PostDetail} />
                <Route path="activity/:activity_id/info" element={ActivitiesInfo} />
                {/* <Route path="requests/:request_id" element={<ServiceRequestDetail />} />
            <Route path=":provider_id" element={<ServiceProvider />}>
              <Route path="info" element={<ProviderRequests />} />
              <Route path="detail" element={<ProviderDetail />} />
            </Route> */}
            </Route>
            {/* 智能设备 */}
            <Route path="smart_device" element={EpidemicBox}>
                <Route index element={DevicesList} />
                <Route path="devices" element={MeetingDeviceList} />
                <Route path="lockers" element={LockersBox}>
                    <Route index element={Lockers} />
                    <Route path="records" element={LockerRecord} />
                    <Route path="categories" element={LockerCategories} />
                    <Route path="settings" element={LockerSettings} />
                </Route>
                <Route path="printers" element={PrinterManage} />
                <Route path="lighting" element={LightingDeviceManage} />
                <Route path="sensor" element={SensorDeviceManage} />
                {/* <Route path='epidemic_white_list' element={<EpidemicWhiteList />} /> */}
            </Route>
            {/* 智能设备 */}
            <Route path="property_meter_reading" element={IndexRedirectBox}>
                <Route index element={DevicesList} />
            </Route>
            {visitorRoutes()}
            {organizationRoutes()}
            {invoiceRoutes()}
            {memberRoutes()}
            {shoppingRoutes()}
            {operationRoutes()}
            {assetResourceRoutes()}
            {taskRoutes()}


            {/* 垂直物流 */}
            {/* {verticalLogisticsRoutes()} */}


            {/* 停车管理 */}
            {parkingRoutes()}
            {locationSettingRoutes()}
        </Route>
    )
}
